import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { AccessRestrictionType, IFeedItem } from 'api/feed/types';
import { selectIsJoinedGroupMember } from 'store/selectors';
import { useController } from 'common/context/controller';

import { Card } from 'wui/Card';
import { Stack } from 'wui/Stack';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Collapse } from 'wui/Collapse';
import { Divider } from 'wui/Divider';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { Comments } from './Comments/loadable';
import { Reactions } from './Reactions';
import { FeedItemMenu } from './FeedItemMenu';
import { FeedItemHeader } from './FeedItemHeader';
import { FeedItemContent } from './FeedItemContent';
import { Disclaimer } from './Disclaimer';
import { ReactedMembers } from './ReactedMembers';
import { FeedItemPreview } from './FeedItemPreview';

import { getDescribedBy, getLabelledBy } from './a11y';
import { FEED_ITEM } from './dataHooks';
import { FeedItemTopics } from './FeedItemTopics';

import classes from './FeedItem.scss';

interface IProps extends React.ComponentProps<typeof Card> {
  focused?: boolean;
  item: IFeedItem;
  truncate?: boolean;
  promote?: boolean;
  comments?: boolean;
}

export const FeedItem = React.memo((props: IProps) => {
  const { item, truncate, promote, focused, comments, ...rest } = props;

  const { isMobile } = useEnvironment();
  const { comments$ } = useController();

  const ref = React.useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(comments ?? !isMobile);

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const suggestToJoin = !isJoined && promote;
  const isRestrictedByPP =
    item.accessRestriction?.type === AccessRestrictionType.PAID_PLANS;

  React.useEffect(() => {
    if (focused) {
      ref.current?.focus({ preventScroll: true });
    }
  }, [focused, ref.current]);

  return (
    <Card
      ref={ref}
      role="article"
      tabIndex={0}
      data-hook={FEED_ITEM}
      className={classes.root}
      sideBorders={!isMobile}
      aria-labelledby={getLabelledBy(feedItemId)}
      aria-describedby={getDescribedBy(feedItemId)}
      {...rest}
    >
      <Stack
        gap="SP3"
        direction="vertical"
        separator={<Divider inset aria-hidden="true" />}
      >
        <Show
          if={Boolean(item.pin) || Boolean(suggestToJoin) || isRestrictedByPP}
        >
          <Disclaimer
            isPinned={Boolean(item.pin)}
            item={item}
            isSuggested={Boolean(suggestToJoin)}
            isRestrictedByPP={isRestrictedByPP}
          />
        </Show>
        <FeedItemHeader
          item={item}
          promote={promote}
          action={<FeedItemMenu item={item} />}
        />
      </Stack>

      <Show if={Boolean(item.permissions?.canViewFullPost)}>
        <FeedItemContent item={item} truncate={truncate} />
      </Show>

      <Hide if={Boolean(item.permissions?.canViewFullPost)}>
        <FeedItemPreview item={item} isSuggested={Boolean(suggestToJoin)} />
      </Hide>

      <FeedItemTopics item={item} limit={isMobile ? 2 : 5} />

      <Reactions
        item={item}
        onReact={item.permissions?.canAddComment ? openCommentBox : undefined}
        toggleComments={
          item.permissions?.canViewFullPost ? handleToggleComments : undefined
        }
      />

      <ReactedMembers
        item={item}
        toggleComments={
          item.permissions?.canViewFullPost ? handleToggleComments : undefined
        }
      />

      <Show if={item.permissions?.canViewFullPost}>
        <Collapse isOpened={expanded} unmountOnExit>
          <Comments
            item={item}
            auto={isMobile}
            fallback={<EmptyState variant="section" title={<Spinner />} />}
          />
        </Collapse>
      </Show>
    </Card>
  );

  function openCommentBox() {
    if (expanded) {
      return;
    }

    setExpanded(true);
    comments$.openCommentBox(feedItemId, {
      shouldFocus: true,
      shouldScroll: true,
    });
  }

  function handleToggleComments() {
    setExpanded((expanded) => !expanded);
  }
});

FeedItem.displayName = 'FeedItem';
